<template>
  <div id="app">
    <!-- Bước 1: Hiển thị thông tin nhân viên, giờ check-in và lời chúc -->
    <div v-if="step === 1" class="employee-info">
      <img class="avatar" :src="employee.avatar" alt="Avatar" />
      <div class="info">
        <h1>{{ employee.name }}</h1>
        <p class="department">{{ employee.department }}</p>
        <p class="email">{{ employee.email }}</p>
        <p class="checkin-time">{{ checkinTime }}</p>
        <p class="greeting" v-show="step === 1">Xin chào {{ employee.name }}! Chúc bạn 1 ngày làm việc vui vẻ, hiệu quả!</p>
      </div>
    </div>

    <!-- Bước 2: Giao diện đếm giờ làm việc -->
    <div v-if="step === 2" class="working-time">
      <h2>Thời gian làm việc</h2>
      <div class="time-circle">
        <span>{{ formattedWorkingTime }}</span>
      </div>
    </div>

    <!-- Bước 3: Màn hình tóm tắt kết quả chấm công -->
    <div v-if="step === 3" class="checkout-summary">
      <h2>Tóm tắt kết quả chấm công</h2>
      <div class="employee-info">
        <img class="avatar" :src="employee.avatar" alt="Avatar" />
        <div class="info">
          <h1>{{ employee.name }}</h1>
          <p class="email">Email: {{ employee.email }}</p>
        </div>
      </div>
      <p>Thời gian làm việc: {{ formattedWorkingTime }}</p>
      <p class="day-summary">Ngày công hôm nay: 1</p> <!-- Hiển thị ngày công hôm nay -->
      <p class="thank-you">Cảm ơn bạn vì một ngày làm việc hiệu quả! Chúc bạn có 1 buổi tối thú vị!</p>
      <button @click="nextS" class="checkout-btn">Next step</button>
    </div>

    <!-- Button to checkout (Step 2) -->
    <button v-if="step === 2" @click="checkout" class="checkout-btn">Checkout</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      step: 1, // Bước ban đầu (1: thông tin nhân viên, 2: đếm giờ, 3: tóm tắt)
      employee: {
        name: 'LÊ THỊ DUNG',
        department: 'Phòng Kỹ Thuật',
        email: 'ledung@hkoil.org',
        avatar: 'https://cdn.hkoil.org/img/checkin/dungle.jpg', // Image Placeholder
      },
      checkinTime: '08:00:00', // Giờ check-in
      startTime: new Date(new Date().setHours(8, 0, 0, 0)),// Thời gian check-in
      currentTime: new Date(),
      timer: null, // Biến lưu trữ timer để có thể clear
    };
  },
  computed: {
    formattedWorkingTime() {
      const diff = new Date(this.currentTime - this.startTime);
      const hours = String(diff.getUTCHours()).padStart(2, '0');
      const minutes = String(diff.getUTCMinutes()).padStart(2, '0');
      const seconds = String(diff.getUTCSeconds()).padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    },
  },
  methods: {
    updateTime() {
      this.currentTime = new Date();
    },
    checkout() {
      clearInterval(this.timer); // Dừng timer khi checkout
      this.step = 3; // Chuyển sang bước 3 khi click checkout
    },
    nextS() {
          // Chuyển hướng đến trang /att-pe
          this.$router.push('/att-pe');
    },
  },
  mounted() {
    this.timer = setInterval(this.updateTime, 1000); // Cập nhật thời gian mỗi giây
    setTimeout(() => {
      this.step = 2; // Chuyển sang bước 2 sau 20s
    }, 8000);
  },
};
</script>

<style scoped>
#app {
  font-family: 'Arial', sans-serif;
  background: linear-gradient(45deg, #ff6b6b, #f3c47b, #f884f6);
  background-size: 300% 300%;
  animation: gradientAnimation 6s ease infinite;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #fff;
  flex-direction: column;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.employee-info, .working-time, .checkout-summary {
  text-align: center;
  margin-top: 20px;
  width: 100%;
}

.avatar {
  border-radius: 50%;
  width: 240px;
  height: 240px;
  object-fit: cover;
  margin-bottom: 20px;
  border: 3px solid #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.info h1 {
  font-size: 28px;
  font-weight: bold;
}

.checkin-time {
  font-size: 24px;
  font-weight: bold;
  color: #2ecc71; /* Nổi bật thời gian check-in với màu xanh lá */
}

.greeting {
  font-size: 20px;
  color: #fff;
  animation: greetingMessage 3s ease-in-out;
  padding: 10px;
  border-radius: 15px;
  background: linear-gradient(45deg, #ff6b6b, #f39c12);
  display: inline-block;
}

@keyframes greetingMessage {
  0% { opacity: 0; transform: translateY(-20px); }
  50% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(20px); }
}

.working-time h2, .checkout-summary h2 {
  font-size: 24px;
  font-weight: bold;
}

.time-circle {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: #2ecc71;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: bold;
  margin: 20px auto;
  position: relative;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.checkout-btn {
  padding: 12px 30px;
  font-size: 18px;
  font-weight: bold;
  background: linear-gradient(45deg, #ff6b6b, #f39c12, #f19f9f); /* Gradient sinh động */
  color: white;
  border: none;
  border-radius: 30px; /* Bo góc mềm mại */
  cursor: pointer;
  margin-top: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Đổ bóng nhẹ tạo chiều sâu */
  transition: all 0.3s ease-in-out; /* Thêm hiệu ứng chuyển động */
}

.checkout-btn:hover {
  transform: translateY(-5px); /* Nâng nút lên khi hover */
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3); /* Tăng bóng khi hover */
  background: linear-gradient(45deg, #f39c12, rgba(209, 132, 132, 0.15), #ff6b6b); /* Đảo ngược gradient khi hover */
}

.checkout-btn:active {
  transform: translateY(2px); /* Nhấn nút xuống khi click */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Bóng nhẹ khi click */
}

.thank-you {
  font-size: 18px;
  font-weight: bold;
  color: #8b6be1; /* Màu chữ vàng nổi bật */
  background: linear-gradient(45deg, #f8f2f2, #f39c12); /* Gradient xanh lá và vàng */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Đổ bóng nhẹ để làm nổi bật */
  animation: gradientEffect 3s ease-in-out infinite; /* Thêm hiệu ứng gradient động */
}

@keyframes gradientEffect {
  0% {
    background: linear-gradient(45deg, #ffffff, #f39c12);
  }
  50% {
    background: linear-gradient(45deg, #ffffff, #f1c40f); /* Thay đổi màu sắc của gradient */
  }
  100% {
    background: linear-gradient(45deg, #ffffff, #f39c12);
  }
}

.day-summary {
  font-size: 16px;
  color: #fff;
  margin-top: 20px;
}
</style>
