import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'; // Import plugin
import './registerServiceWorker';
import { usePWAStore } from './stores/usePWAStore'; // Import store

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate); // Sử dụng plugin persisted state

const app = createApp(App);

// Lắng nghe sự kiện beforeinstallprompt
window.addEventListener('beforeinstallprompt', (e) => {
  const pwaStore = usePWAStore(); // Lấy store PWA
  e.preventDefault(); // Ngăn chặn hiển thị tự động
  pwaStore.savePrompt(e); // Lưu prompt vào store
});

app.use(router);
app.use(pinia);
app.mount('#app');
