import axios from 'axios';
import { useAuthStore } from '@/stores/authStore'; // Thêm import store
//http://localhost:3000/api
const api = axios.create({
  baseURL: 'https://hk-auth.onrender.com/api',
});

// Interceptor để thêm accessToken vào headers cho mỗi request
api.interceptors.request.use(
  (config) => {
    const authStore = useAuthStore(); // Lấy store trực tiếp
    const token = authStore.accessToken; // Lấy accessToken từ store
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // Thêm token vào header
    }
    return config; // Trả về config đã chỉnh sửa
  },
  (error) => Promise.reject(error) // Xử lý lỗi request
);

// Interceptor để làm mới accessToken khi hết hạn
api.interceptors.response.use(
  (response) => response, // Trả về response bình thường
  async (error) => {
    const originalRequest = error.config; // Lưu original request

    // Nếu lỗi 401 và không phải là lần thử lại
    if (error.response && error.response.status === 401) {
      const authStore = useAuthStore(); // Lấy store để xử lý refresh token
      if (originalRequest.url.includes('/login')) {
        // Nếu là request đăng nhập, không gọi refresh token
        return Promise.reject(new Error('Login failed. Please check your credentials.'));
      }

      // Nếu không phải request đăng nhập, thử gọi refresh token
      if (!originalRequest._retry) {
        originalRequest._retry = true; // Đánh dấu rằng đã thử lại

        try {
          await authStore.refreshAccessToken(); // Gọi hàm làm mới token
          const newAccessToken = authStore.accessToken; // Lấy accessToken mới
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`; // Cập nhật token
          return api(originalRequest); // Thực hiện lại request ban đầu
        } catch (refreshError) {
          console.error('Error refreshing access token:', refreshError);
          // Nếu refresh token không thành công, xóa token và thông báo lỗi
          authStore.clearTokens();
          return Promise.reject(new Error('Session expired. Please login again.')); // Ném lỗi thông báo cho component
        }
      }
    }

    return Promise.reject(error); // Trả về lỗi nếu không phải lỗi 401
  }
);

export default api; // Xuất api để sử dụng ở các phần khác
