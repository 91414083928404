<template>
  <div>
    <h2>Biểu đồ giá gas</h2>
    <Line :data="chartData" :options="chartOptions" v-if="chartData.datasets.length > 0" />
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { Line } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement, LinearScale, CategoryScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, LinearScale, CategoryScale);

export default {
  components: {
    Line,
  },
  props: {
    gasData: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const chartData = ref({
      labels: [], // Danh sách nhãn cho trục x (ngày)
      datasets: [], // Danh sách datasets cho từng cặp vùng và sản phẩm
    });

    const chartOptions = {
      responsive: true,
      plugins: {
        legend: {
          display: true,
        },
      },
    };

    // Hàm cập nhật dữ liệu biểu đồ
    const updateChartData = () => {
      const groupedData = {};

      // Nhóm dữ liệu theo (vùng, sản phẩm)
      props.gasData.forEach(item => {
        const date = item['Ngày bắt đầu hiệu lực'];
        const region = item['Vùng'];
        const product = item['Sản phẩm'];
        const price = item['Giá'];

        // Tạo key cho cặp vùng và sản phẩm
        const key = `${region}-${product}`;

        if (!groupedData[key]) {
          groupedData[key] = {
            label: key,
            data: [],
            borderColor: getRandomColor(), // Tạo màu ngẫu nhiên cho mỗi cặp
            fill: false,
          };
        }

        // Thêm giá vào dữ liệu của cặp vùng và sản phẩm
        groupedData[key].data.push({ x: date, y: price });
      });

      // Chuyển đổi groupedData thành dạng datasets
      chartData.value.labels = props.gasData.map(item => item['Ngày bắt đầu hiệu lực']); // Lấy danh sách nhãn cho trục x
      chartData.value.datasets = Object.values(groupedData); // Lấy dữ liệu cho từng cặp
    };

    watch(() => props.gasData, updateChartData, { immediate: true });

    // Hàm tạo màu ngẫu nhiên
    function getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }

    return {
      chartData,
      chartOptions,
    };
  },
};
</script>
