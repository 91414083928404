<template>
  <div>
    <h1>Thông tin giá xăng</h1>
    <div v-if="gasData.length > 0">
      <div v-for="(item, index) in gasData" :key="index">
        <p>Ngày bắt đầu hiệu lực: {{ item['Ngày bắt đầu hiệu lực'] }}</p>
        <p>Sản phẩm: {{ item['Sản phẩm'] }}</p>
        <p>Giá: {{ item['Giá'] }} VND</p>
        <p>Vùng: {{ item['Vùng'] }}</p>
        <hr /> <!-- Dùng để tạo đường ngăn cách giữa các bản ghi -->
      </div>
    </div>

    <GasPriceChart :gasData="gasData" />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import GasPriceChart from './GasPriceChart.vue'; // Import component biểu đồ
import { useGasStore } from '@/stores/gasStore';

export default {
  components: {
    GasPriceChart,
  },
  setup() {
    const gasData = ref([]); // Sử dụng ref để lưu trữ gasData
	const gasStore = useGasStore(); // Khởi tạo gasStore

    const fetchGasData = async () => {
      try {
        const response = await axios.get(gasStore.gasUrl); // Thay YOUR_API_URL bằng URL thật
        gasData.value = response.data; // Gán dữ liệu JSON vào gasData
      } catch (error) {
        console.error('Error fetching gas data:', error);
      }
    };

    onMounted(() => {
      fetchGasData(); // Gọi hàm khi component được gắn vào DOM
    });

    return {
      gasData,
    };
  },
};
</script>
