<template>
  <div class="homepage">
    <section class="hero">
      <h1>Giới Thiệu Lãnh Đạo</h1>
      <p>Ông Harry Tran - Chuyên gia công nghệ thông tin và chuyển đổi số</p>
    </section>
    <section class="content">
      <div class="profile">
        <img src="../assets/logo.png" alt="Ông Harry Tran" class="profile-img" />
        <div class="profile-info">
          <h2>Ông Harry Tran</h2>
          <p class="title">Chuyên gia công nghệ thông tin và chuyển đổi số</p>
          <button @click="toggleContent" class="toggle-btn">{{ isContentVisible ? 'Ẩn Nội Dung' : 'Hiện Nội Dung' }}</button>
          <div v-show="isContentVisible" class="profile-text">
            <p>Ông Harry Tran là một chuyên gia dày dạn kinh nghiệm trong lĩnh vực công nghệ thông tin và chuyển đổi số.
            Ông tốt nghiệp Đại học và Thạc sĩ tại trường Đại học Bách Khoa Hà Nội, Việt Nam. Với hơn 12 năm
            kinh nghiệm, ông đã đảm nhiệm nhiều vị trí quản lý và chuyên gia công nghệ cấp cao tại các tập đoàn
            hàng đầu như Viettel và tại Tổ hợp Giáo dục TOPICA. Trong sự nghiệp của mình, ông Harry Tran đã dẫn dắt và
            phụ trách nhiều dự án công nghệ then chốt.</p>
            <p>Tại Viettel, ông đã triển khai các hệ thống công nghệ thông tin phục vụ sản xuất kinh doanh, bao gồm
            các hệ thống dành cho khối chính phủ và các hệ thống phục vụ kinh doanh tại 10 thị trường quốc tế
            của Viettel. Ông cũng đã tích lũy được nhiều kinh nghiệm trong việc triển khai các hệ thống quản trị
            nội bộ trong tập đoàn.</p>
            <p>Ông gia nhập HKOIL vào năm 2024 và đang có những đóng góp quan trọng cho sự phát triển vượt bậc của
            công ty. Ông và đội ngũ của mình đang từng bước đưa HKOIL trở thành công ty tiên phong về công nghệ
            trong lĩnh vực phân phối và kinh doanh xăng dầu.</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  data() {
    return {
      isContentVisible: true
    };
  },
  methods: {
    toggleContent() {
      this.isContentVisible = !this.isContentVisible;
    }
  }
};
</script>

<style scoped>
.homepage {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  line-height: 1.6;
  padding: 10px 5px 5px 10px;
  background: linear-gradient(to right, #f8f9fa, #e9ecef);
  max-width: 1200px; /* Đặt kích thước tối đa cho trang */
  margin: 0 auto; /* Trung tâm hóa trang */
}

.hero {
  background: linear-gradient(to right, #007bff, #6610f2);
  color: white;
  padding: 40px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  margin-bottom: 20px; /* Thêm khoảng cách dưới hero */
}

.hero h1 {
  font-size: 2.5em;
  margin: 0;
  font-weight: 700;
}

.hero p {
  font-size: 1.2em;
  margin: 10px 0;
  font-weight: 300;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.profile {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.profile-img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-right: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.profile-info {
  flex: 1; /* Để nội dung chiếm toàn bộ không gian còn lại */
  text-align: left; /* Căn chỉnh chữ bên trái */
}

.profile-info h2 {
  font-size: 2em;
  color: #333;
  margin-bottom: 10px;
  font-weight: 600;
}

.profile-info .title {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 20px;
  font-weight: 400;
}

.profile-info .toggle-btn {
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
  margin-bottom: 20px;
  transition: background 0.3s ease;
}

.profile-info .toggle-btn:hover {
  background: #0056b3;
}

.profile-text {
  margin-bottom: 15px;
  font-size: 1em;
  color: #555;
  /* Thêm chiều cao tối thiểu nếu cần */
  min-height: 100px; /* Ví dụ, chiều cao tối thiểu khi ẩn */
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero {
    padding: 30px;
  }

  .hero h1 {
    font-size: 2em;
  }

  .hero p {
    font-size: 1em;
  }

  .profile {
    flex-direction: column; /* Chuyển đổi bố cục thành cột trên màn hình nhỏ */
    align-items: center; /* Căn giữa nội dung */
    padding: 15px;
  }

  .profile-img {
    width: 120px;
    height: 120px;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .profile-info {
    text-align: center; /* Căn chỉnh chữ ở giữa */
  }

  .profile-info h2 {
    font-size: 1.5em;
  }

  .profile-info .title {
    font-size: 1em;
  }

  .profile-info .toggle-btn {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .hero {
    padding: 20px;
  }

  .hero h1 {
    font-size: 1.5em;
  }

  .hero p {
    font-size: 0.9em;
  }

  .profile {
    padding: 10px;
  }

  .profile-img {
    width: 100px;
    height: 100px;
  }

  .profile-info h2 {
    font-size: 1.2em;
  }

  .profile-info .title {
    font-size: 0.9em;
  }

  .profile-info .toggle-btn {
    font-size: 0.8em;
  }

  .profile-text {
    font-size: 0.9em;
  }
}
</style>
