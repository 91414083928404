<template>
  <div class="system-list">
    <section class="header1">
      <h1>Danh sách Hệ Thống HKOIL</h1>
    </section>
    <div class="system-container">
      <div
        v-for="system in paginatedSystems"
        :key="system.id"
        class="system-card"
      >
        <a :href="system.link" target="_blank">
          <img
            :src="system.image ? system.image : '../assets/logo.png'"
            :alt="system.name"
          />
        </a>
        <a :href="system.link" target="_blank">{{ system.name }}</a>
      </div>
    </div>
    <div class="pagination">
      <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SystemList",
  props: {
    systems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 8, // Hiển thị 4 items/hàng, 2 hàng.
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.systems.length / this.itemsPerPage);
    },
    paginatedSystems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.systems.slice(start, start + this.itemsPerPage);
    },
  },
  methods: {
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
};
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
  color: #333;
}

.system-list {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.header1 {
  background: linear-gradient(45deg, #2fd93d, #00c6ff);
  color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  margin-bottom: 30px;
  text-align: center;
}

.header1 h1 {
  margin: 0;
  font-size: 2.5em;
  font-weight: bold;
}

.system-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.system-card {
  flex: 1 1 calc(25% - 20px);
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 20px;
  transition: all 0.3s ease;
  cursor: pointer;
}

@media (max-width: 992px) {
  .system-card {
    flex: 1 1 calc(33.33% - 20px); /* 3 items mỗi hàng */
  }
}

@media (max-width: 768px) {
  .system-card {
    flex: 1 1 calc(50% - 20px); /* 2 items mỗi hàng */
  }
}

@media (max-width: 576px) {
  .system-card {
    flex: 1 1 100%; /* 1 item mỗi hàng */
  }
}

.system-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  background-color: #d9f6d3;
}

.system-card img {
  width: 160px;
  height: 160px;
  margin-bottom: 15px;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.system-card:hover img {
  transform: scale(1.1);
}

.system-card a {
  display: block;
  text-decoration: none;
  color: #333;
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 10px;
  transition: color 0.3s ease;
}

.system-card a:hover {
  color: #007bff;
}

.pagination {
  text-align: center;
  margin-top: 20px;
}

.pagination button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination button:disabled {
  background-color: #bbb;
}

.pagination span {
  margin: 0 10px;
}
</style>
