<template>
  <div class="loan-calculator-container">
    <div class="calculator-card">
      <h2 class="calculator-title">Tính Toán Lãi Suất Vay</h2>
      <form @submit.prevent="calculateLoan" class="calculator-form">
        <div class="input-group">
          <label for="principal">Vốn vay (VNĐ)</label>
          <input v-model="principal" id="principal" placeholder="Nhập vốn vay" type="text"
                 @input="formatPrincipal" :class="{'invalid': !isValidPrincipal}" />
          <span v-if="!isValidPrincipal" class="error-message">Vốn vay phải là số lớn hơn 0 và có định dạng đúng.</span>
        </div>
        <div class="input-group">
          <label for="rate">Lãi suất hàng năm (%)</label>
          <input v-model.number="rate" id="rate" placeholder="Nhập lãi suất" type="number" min="0" max="30"
          step="0.01" />
        </div>
        <div class="input-group">
          <label for="time">Thời gian vay (tháng)</label>
          <input v-model.number="time" id="time" placeholder="Nhập số tháng" type="number" min="1" />
        </div>
        <div class="input-group">
          <label for="loanType">Loại vay</label>
          <select v-model="loanType" id="loanType">
            <option value="fixed">Cố định</option>
            <option value="declining">Giảm dần</option>
          </select>
        </div>

        <!-- Buttons -->
        <div class="button-group">
          <button type="submit" class="calculate-button" :disabled="!isValidPrincipal">Tính Toán</button>
          <button type="button" class="reset-button" @click="resetForm">Làm Mới</button>
        </div>
      </form>

      <div v-if="loanResult" class="result-container">
        <div v-if="loanType === 'fixed'" class="result-summary">
          <p>Số tiền trả hàng tháng: <span class="formatted-money">{{ formattedMonthlyPayment }}</span></p>
        </div>

        <div v-if="loanType === 'declining'" class="result-table">
          <table>
            <thead>
              <tr>
                <th>Tháng</th>
                <th>Thanh toán hàng tháng</th>
                <th>Lãi</th>
                <th>Tiền gốc</th>
                <th>Dư nợ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in loanResult" :key="item.month">
                <td>{{ item.month }}</td>
                <td>{{ formatMoney(item.monthlyPayment) }}</td>
                <td>{{ formatMoney(item.interest) }}</td>
                <td>{{ formatMoney(item.principalPayment) }}</td>
                <td>{{ formatMoney(item.remainingDebt) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useCalculatorStore } from '@/stores/store';

export default {
  data() {
    return {
      principal: '',
      rate: 0,
      time: 0,
      loanType: 'fixed',
      isValidPrincipal: true,
    };
  },
  computed: {
    loanResult() {
      const store = useCalculatorStore();
      return store.loanResult;
    },
    formattedMonthlyPayment() {
      const store = useCalculatorStore();
      const monthlyPayment = store.loanResult?.monthlyPayment || 0;
      return this.formatMoney(monthlyPayment);
    },
  },
  watch: {
    loanType() {
      this.calculateLoan();
    },
  },
  methods: {
    calculateLoan() {
      const store = useCalculatorStore();
      store.calculateLoan(this.principal, this.rate, this.time, this.loanType);
    },
    formatMoney(value) {
      if (value == null || isNaN(value)) {
        return "0 VNĐ";
      }
      return value.toLocaleString('vi-VN', {
        style: 'currency',
        currency: 'VND',
      });
    },
    formatPrincipal() {
      // Loại bỏ ký tự không phải số và kiểm tra tính hợp lệ
      let value = this.principal.replace(/\D/g, ''); // Đảm bảo là chuỗi trước khi dùng replace
      if (value === '' || parseInt(value) <= 0) {
        this.isValidPrincipal = false;
      } else {
        this.isValidPrincipal = true;
      }
      this.principal = value ? parseInt(value).toLocaleString('vi-VN') : '';
    },
    resetForm() {
      this.principal = '';
      this.rate = 0;
      this.time = 0;
      this.loanType = 'fixed';
      this.isValidPrincipal = true;
    },
  },
};
</script>

<style scoped>
.loan-calculator-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.calculator-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 600px;
}

.calculator-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.calculator-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.input-group label {
  font-size: 14px;
  margin-bottom: 5px;
}

.input-group input,
.input-group select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.input-group input.invalid {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 12px;
}

.button-group {
  display: flex;
  justify-content: space-between;
}
.calculate-button,
.reset-button {
  font-size: 16px;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}
.calculate-button {
  background-color: #4caf50;
  color: #fff;
  font-size: 18px;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.calculate-button:hover {
  background-color: #45a049;
}
.reset-button {
  background-color: #f44336;
  color: #fff;
  border: none;
}

.reset-button:hover {
  background-color: #e53935;
}


.result-container {
  margin-top: 20px;
}

.result-summary {
  text-align: center;
  font-size: 18px;
}

.result-table {
  margin-top: 20px;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th, td {
  text-align: right;
  padding: 10px;
  border: 1px solid #ddd;
}

th {
  background-color: #f4f4f4;
}

td {
  font-size: 16px;
}

.formatted-money {
  font-weight: bold;
  color: #4caf50;
}

@media (max-width: 600px) {
  .calculator-card {
    padding: 15px;
  }

  .calculator-title {
    font-size: 20px;
  }

  .input-group input,
  .input-group select {
    font-size: 14px;
  }

  .calculate-button {
    font-size: 16px;
    padding: 10px 15px;
  }
}
</style>
