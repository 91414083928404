// src/stores/gasStore.js
import { defineStore } from 'pinia';

export const useGasStore = defineStore('gas', {
  state: () => ({
    gasUrl: 'https://zchatbot.onrender.com/api/Gasdata', //http://localhost:3000/api/Gasdata
    authURL: 'http://localhost:3000/api/', // Thêm thông tin authURL
  }),
  actions: {
    setGasUrl(url) {
      this.gasUrl = url;
    },
    clearGasUrl() {
      this.gasUrl = '';
    },
    setAuthURL(url) {
      this.authURL = url; // Thêm hành động để cập nhật authURL
    },
    clearAuthURL() {
      this.authURL = ''; // Thêm hành động để xóa authURL
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'gasStore', // Đổi khóa lưu trữ để bao gồm tất cả thông tin
        storage: localStorage, // Hoặc sessionStorage nếu cần
        paths: ['gasUrl', 'authURL'], // Chỉ định các state cần lưu trữ
      },
    ],
  },
});