<template>
  <div id="app">
    <!-- Bước 1: Thông báo chúc mừng gia đình -->
    <div v-if="step === 1" class="notification step-1">
      <div class="icon">
        <i class="fas fa-bell ring"></i>
      </div>
      <h2>Gia đình ThaoTV5 vừa chào đón thành viên mới, hãy gửi lời chúc mừng đến anh ấy!</h2>
      <img src="https://cdn.hkoil.org/img/checkin/c1.webp" alt="Chúc mừng" />
    </div>

    <!-- Bước 2: Thông báo Huyền đi làm trễ -->
    <div v-if="step === 2" class="notification step-2">
      <div class="icon">
        <i class="fas fa-bell ring"></i>
      </div>
      <h2>Đã là lần thứ 3 liên tiếp Huyền đi làm trễ giờ? Có thể cô ấy đang gặp vấn đề gì cần giúp đỡ chứ? Hãy trao đổi thêm với cô ấy.</h2>
      <img src="https://cdn.hkoil.org/img/checkin/c3.webp" alt="Trễ giờ" />
    </div>

    <!-- Bước 3: Thông báo Dung hoàn thành khóa học AI -->
    <div v-if="step === 3" class="notification step-3">
      <div class="icon">
        <i class="fas fa-bell ring"></i>
      </div>
      <h2>Dung vừa hoàn thành khóa học nhập môn AI và ứng dụng cải thiện năng suất lao động, hãy chúc mừng cô ấy!</h2>
      <img src="https://cdn.hkoil.org/img/checkin/c2.webp" alt="Hoàn thành AI" />
    </div>

    <!-- Bước 4: Thông báo Giang hoàn thành chứng chỉ -->
    <div v-if="step === 4" class="notification step-4">
      <div class="icon">
        <i class="fas fa-bell ring"></i>
      </div>
      <h2>Giang đã hoàn thành các chứng chỉ cần thiết cho chức danh chuyên gia phân tích tài chính, vui lòng xem lại điều kiện bổ nhiệm của anh ấy!</h2>
      <img src="https://cdn.hkoil.org/img/checkin/c4.webp" alt="Chứng chỉ" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      step: 1, // Bước ban đầu
    };
  },
  methods: {
    // Chuyển bước sau thời gian nhất định
    goToNextStep() {
      setTimeout(() => {
        this.step = 2; // Chuyển sang bước 2 sau 10s
        setTimeout(() => {
          this.step = 3; // Chuyển sang bước 3 sau 10s
          setTimeout(() => {
            this.step = 4; // Chuyển sang bước 4 sau 10s
          }, 10000); // Thời gian chờ cho bước 4
        }, 10000); // Thời gian chờ cho bước 3
      }, 10000); // Thời gian chờ cho bước 2
    },
  },
  mounted() {
    this.goToNextStep(); // Khởi động quá trình chuyển bước
  },
};
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

#app {
  font-family: 'Arial', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  text-align: center;
  background: linear-gradient(45deg, #ff6b6b, #f3c47b, #f884f6);
  background-size: 300% 300%;
  animation: gradientAnimation 6s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.notification {
  font-size: 24px;
  font-weight: bold;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  opacity: 0;
  animation: fadeIn 3s forwards;
}

.step-1 {
  background: linear-gradient(45deg, #ff6b6b, #f39c12);
}

.step-2 {
  background: linear-gradient(45deg, #2ecc71, #3498db);
}

.step-3 {
  background: linear-gradient(45deg, #f39c12, #f1c40f);
}

.step-4 {
  background: linear-gradient(45deg, #8e44ad, #9b59b6);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Icon chuông rung */
.icon {
  font-size: 50px;
  margin-bottom: 10px;
}

.ring {
  animation: ringAnimation 1s ease infinite;
}

@keyframes ringAnimation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Ảnh minh họa */
.notification img {
  margin-top: 10px;
  width: 500px; /* Điều chỉnh kích thước ảnh */
  height: 500px; /* Điều chỉnh kích thước ảnh */
  object-fit: cover;
  border-radius: 50%; /* Tạo ảnh tròn */
}
</style>
