import { defineStore } from 'pinia';
import axios from '@/api/axios'; // Sử dụng axios từ cấu hình đã tạo

export const useAuthStore = defineStore('auth', {
  state: () => ({
    accessToken: '',
    refreshToken: '',
    isAuthenticated: false, // Thêm biến isAuthenticated
  }),
  actions: {
    setTokens(accessToken, refreshToken) {
      this.accessToken = accessToken;
      this.refreshToken = refreshToken;
      this.isAuthenticated = true; // Đặt isAuthenticated là true khi set token thành công
      document.cookie = `refreshToken=${refreshToken}; Secure; SameSite=Strict;`; // Lưu refreshToken vào cookie
    },
    clearTokens() {
      this.accessToken = '';
      this.refreshToken = '';
      this.isAuthenticated = false; // Đặt isAuthenticated là false khi xóa token
      document.cookie = `refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`; // Xóa cookie
    },
    async login(credentials) {
      try {
        const response = await axios.post('/auth/login', credentials); // Gọi API đăng nhập
        this.setTokens(response.data.accessToken, response.data.refreshToken); // Lưu token vào store
      } catch (error) {
        console.error('Login error:', error);
        throw error; // Ném lại lỗi để có thể xử lý ở component
      }
    },
    async refreshAccessToken() {
      try {
        const response = await axios.post('/auth/refresh-token', {
          refreshToken: this.refreshToken, // Gửi refreshToken để lấy accessToken mới
        });
        this.accessToken = response.data.accessToken; // Cập nhật accessToken mới
      } catch (error) {
        console.error('Failed to refresh access token:', error);
        this.clearTokens(); // Xóa token nếu refresh không thành công
      }
    },
  },
  persist: {
    enabled: true, // Bật persist
    strategies: [
      {
        key: 'authStore', // Tên khóa lưu trữ
        storage: localStorage, // Lưu trữ trong localStorage
        paths: ['accessToken', 'refreshToken', 'isAuthenticated'], // Các thuộc tính cần lưu trữ
      },
    ],
  },
});
