<template>
  <div class="calculator-container">
    <h2 class="title">Tính Toán Thời Gian Hoàn Vốn Trạm Xăng</h2>
    <form @submit.prevent="calculateFuelInvestment" class="form">
      <div class="form-group">
        <label for="fuelCost">Tổng Chi phí hàng tháng (VND):</label>
        <input
          id="fuelCost"
          v-model="formattedFuelCost"
          placeholder="Nhập tổng chi phí"
          type="text"
          class="input"
          @input="updateValue('fuelCost', $event.target.value)"
        />
      </div>

      <div class="form-group">
        <label for="revenue">Doanh thu mỗi tháng (VND):</label>
        <input
          id="revenue"
          v-model="formattedRevenue"
          placeholder="Nhập doanh thu"
          type="text"
          class="input"
          @input="updateValue('revenue', $event.target.value)"
        />
      </div>

      <div class="form-group">
        <label for="investment">Vốn đầu tư ban đầu (VND):</label>
        <input
          id="investment"
          v-model="formattedInvestment"
          placeholder="Nhập vốn đầu tư"
          type="text"
          class="input"
          @input="updateValue('investment', $event.target.value)"
        />
      </div>

      <div class="button-group">
        <button type="submit" class="button calculate-button">Tính</button>
        <button type="button" @click="resetForm" class="button reset-button">Làm mới</button>
      </div>
    </form>
    <div v-if="error" class="error-message">
      {{ error }}
    </div>

    <div v-if="fuelInvestmentResult" class="result">
      <p>Thời gian hoàn vốn: <strong>{{ fuelInvestmentResult.months }} tháng</strong></p>
    </div>
  </div>
</template>

<script>
import { useCalculatorStore } from '@/stores/store';

export default {
  data() {
    return {
      fuelCost: 0,
      revenue: 0,
      investment: 0,
      error: '',
    };
  },
  computed: {
    formattedFuelCost() {
      return this.formatCurrency(this.fuelCost);
    },
    formattedRevenue() {
      return this.formatCurrency(this.revenue);
    },
    formattedInvestment() {
      return this.formatCurrency(this.investment);
    },
    fuelInvestmentResult() {
      const store = useCalculatorStore();
      return store.fuelInvestmentResult;
    },
  },
  methods: {
    calculateFuelInvestment() {
      try {
        const store = useCalculatorStore();
        store.fuelInvestmentCalculator(this.fuelCost, this.revenue, this.investment);
        this.error = ''; // Reset lỗi nếu thành công
      } catch (err) {
        this.error = err.message; // Hiển thị lỗi nếu xảy ra
      }
    },
    updateValue(field, value) {
      // Xóa định dạng và cập nhật giá trị thực
      const numericValue = parseInt(value.replace(/\D/g, ''), 10) || 0;
      this[field] = numericValue;
    },
    formatCurrency(value) {
      // Định dạng số thành tiền VND
      return value ? value.toLocaleString('vi-VN') : '';
    },
    resetForm() {
      this.fuelCost = 0;
      this.revenue = 0;
      this.investment = 0;
      this.error = ''; // Xóa lỗi
      const store = useCalculatorStore();
      store.fuelInvestmentResult = null; // Xóa kết quả cũ
    },
  },
};
</script>

<style scoped>
.calculator-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 1.5rem;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #333;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
  color: #555;
}

.input {
  padding: 0.6rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.button-group {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.button {
  padding: 0.6rem 1.2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.calculate-button {
  background-color: #4caf50;
  color: #fff;
}

.reset-button {
  background-color: #f44336;
  color: #fff;
}

.result {
  margin-top: 1.5rem;
  padding: 1rem;
  background: #e7f4e4;
  border: 1px solid #4caf50;
  border-radius: 4px;
}
</style>
