<template>
  <div class="saving-calculator-container">
    <h2 class="title">Tính Toán Lãi Suất Tiết Kiệm</h2>
    <form @submit.prevent="calculateSaving" class="form">
      <div class="form-group">
        <label for="principal">Vốn tiết kiệm (VND):</label>
        <input
          id="principal"
          v-model="formattedPrincipal"
          placeholder="Nhập số vốn tiết kiệm"
          type="text"
          class="input"
          @input="formatPrincipal"
        />
      </div>

      <div class="form-group">
        <label for="rate">Lãi suất hàng năm (%):</label>
        <input
          id="rate"
          v-model.number="rate"
          placeholder="Nhập lãi suất"
          type="number"
          class="input"
          min="0"
          step="0.01"
        />
      </div>

      <div class="form-group">
        <label for="time">Thời gian gửi (tháng):</label>
        <input
          id="time"
          v-model.number="time"
          placeholder="Nhập thời gian gửi"
          type="number"
          class="input"
          min="1"
        />
      </div>

      <div class="button-group">
        <button type="submit" class="button calculate-button">Tính</button>
        <button type="button" @click="resetForm" class="button reset-button">Làm mới</button>
      </div>
    </form>

    <div v-if="savingResult.length > 0" class="result">
      <table class="result-table">
        <thead>
          <tr>
            <th>Tháng</th>
            <th>Tiền gốc (VND)</th>
            <th>Lãi suất (VND)</th>
            <th>Tổng tiền (VND)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in savingResult" :key="item.month">
            <td>{{ item.month }}</td>
            <td>{{ item.principal.toLocaleString('vi-VN') }}</td>
            <td>{{ item.interest.toLocaleString('vi-VN') }}</td>
            <td>{{ (item.interest + item.principal).toLocaleString('vi-VN') }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { useCalculatorStore } from '@/stores/store';

export default {
  data() {
    return {
      principal: 0,
      rate: 0,
      time: 0,
      formattedPrincipal: '0', // Chuỗi định dạng để hiển thị
    };
  },
  computed: {
    savingResult() {
      const store = useCalculatorStore();
      return store.savingResult || [];
    },
  },
  methods: {
    calculateSaving() {
      const store = useCalculatorStore();
      store.savingCalculator(this.principal, this.rate, this.time);
    },
    formatPrincipal(event) {
      let value = event.target.value.replace(/\D/g, ''); // Loại bỏ ký tự không phải số
      if (value === '' || parseInt(value) <= 0) {
        this.principal = 0;
      } else {
        this.principal = parseInt(value); // Gán giá trị thực cho principal
      }
      this.formattedPrincipal = this.principal
        ? this.principal.toLocaleString('vi-VN')
        : '0';
    },
    resetForm() {
      this.principal = 0;
      this.rate = 0;
      this.time = 0;
      this.formattedPrincipal = '0';
    },
  },
};
</script>

<style scoped>
.saving-calculator-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 1.5rem;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.title {
  text-align: center;
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 0.3rem;
  font-size: 1rem;
  color: #555;
}

.input {
  padding: 0.6rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.button-group {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.button {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.calculate-button {
  background-color: #4caf50;
  color: #fff;
}

.reset-button {
  background-color: #f44336;
  color: #fff;
}

.result {
  margin-top: 2rem;
}

.result-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.result-table th,
.result-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.result-table th {
  background-color: #f4f4f4;
  color: #333;
}
</style>
