import { defineStore } from 'pinia';

export const usePWAStore = defineStore('pwa', {
  state: () => ({
    deferredPrompt: null,
  }),
  actions: {
    savePrompt(prompt) {
      this.deferredPrompt = prompt;
    },
    promptInstall() {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the install prompt');
          } else {
            console.log('User dismissed the install prompt');
          }
          this.deferredPrompt = null; // Reset prompt
        });
      }
    },
  },
});
