<template>
  <div class="login-container">
    <h2 class="login-title">ĐĂNG NHẬP HKOIL</h2>
    <form @submit.prevent="login" class="login-form">
      <div class="form-group">
        <label for="username">Mã Khách Hàng</label>
        <input
          type="text"
          id="username"
          v-model="credentials.username"
          required
        />
      </div>
      <div class="form-group">
        <label for="password">Mật Khẩu</label>
        <input
          type="password"
          id="password"
          v-model="credentials.password"
          required
        />
      </div>
      <button type="submit" class="login-button">Login</button>
    </form>
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useAuthStore } from '@/stores/authStore';
import { useRouter } from 'vue-router'; // Thêm import router

const authStore = useAuthStore(); // Tạo instance của store
const router = useRouter(); // Tạo instance của router
const credentials = ref({
  username: '',
  password: '',
});
const errorMessage = ref('');

const login = async () => {
  try {
    await authStore.login(credentials.value); // Gọi hàm login trong store
    errorMessage.value = ''; // Reset thông báo lỗi nếu đăng nhập thành công
    router.push('/logout'); // Đường dẫn chuyển hướng sau khi đăng nhập thành công
  } catch (error) {
    errorMessage.value = 'Login failed. Please check your credentials.'; // Hiển thị thông báo lỗi
    console.error('Login error:', error);
  }
};
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  transition: all 0.3s ease;
}

.login-title {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 28px; /* Tăng kích thước chữ */
  font-weight: bold; /* Làm đậm chữ */
  background: linear-gradient(253deg, #0d8d39, #c0da67); /* Thêm hiệu ứng gradient */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent; /* Ẩn màu gốc để chỉ hiển thị gradient */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Thêm bóng chữ */
  animation: colorChange 5s ease infinite; /* Thêm chuyển động màu */
}

@keyframes colorChange {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.login-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  display: flex;
  flex-direction: column; /* Đặt label ở trên và input ở dưới */
  margin-bottom: 15px; /* Tạo khoảng cách giữa các form group */
}

.form-group label {
  margin-bottom: 5px; /* Khoảng cách giữa label và input */
  font-weight: bold; /* In đậm label */
}

input[type='text'],
input[type='password'] {
  padding: 10px; /* Khoảng cách bên trong input */
  border: 1px solid #ccc; /* Đường viền cho input */
  border-radius: 4px; /* Bo góc cho input */
  font-size: 16px; /* Kích thước chữ cho input */
}

input[type='text']:focus,
input[type='password']:focus {
  border-color: #4CAF50; /* Đổi màu viền khi focus */
  outline: none; /* Bỏ outline */
}

.login-button {
  padding: 10px; /* Khoảng cách bên trong button */
  background-color: #4CAF50; /* Màu nền cho button */
  color: white; /* Màu chữ của button */
  border: none; /* Bỏ đường viền của button */
  border-radius: 4px; /* Bo góc cho button */
  font-size: 16px; /* Kích thước chữ của button */
  cursor: pointer; /* Hiển thị con trỏ tay khi hover */
  transition: background-color 0.3s; /* Hiệu ứng chuyển màu nền khi hover */
}

.login-button:hover {
  background-color: #45a049; /* Màu nền khi hover */
}

.error-message {
  color: red; /* Màu chữ cho thông báo lỗi */
  text-align: center; /* Căn giữa thông báo lỗi */
  margin-top: 10px; /* Tạo khoảng cách trên thông báo lỗi */
}
</style>
