<template>
  <div class="logout-container">
    <h2 class="logout-title">User Profile</h2>
    <div v-if="loading">Loading...</div>
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    <div v-if="userInfo">
      <p><strong>Email:</strong> {{ userInfo.email }}</p>
      <p><strong>Created At:</strong> {{ userInfo.created_at }}</p>
    </div>
    <button @click="logout" class="logout-button">Logout</button>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import api from '@/api/axios'; // Import API đã cấu hình
import { useAuthStore } from '@/stores/authStore'; // Import auth store
import { useRouter } from 'vue-router'; // Thêm import router

const userInfo = ref(null);
const loading = ref(true);
const errorMessage = ref('');
const authStore = useAuthStore(); // Lấy instance của authStore
const router = useRouter(); // Tạo instance của router

const fetchUserProfile = async () => {
  try {
    const response = await api.get('/user/profile'); // Gọi API lấy thông tin profile
    userInfo.value = response.data; // Lưu thông tin vào userInfo
  } catch (error) {
    errorMessage.value = 'Failed to load profile information.';
    console.error('Profile fetch error:', error);
  } finally {
    loading.value = false; // Đặt loading thành false sau khi hoàn tất
  }
};

// Thêm logic logout
const logout = async () => {
  try {
    await api.post('/auth/logout'); // Gọi API logout
    authStore.clearTokens(); // Xóa token trong store
    authStore.isAuthenticated = false; // Đặt lại isAuthenticated thành false
    //alert('Logout successful!'); // Hiển thị thông báo thành công
    router.push('/');
  } catch (error) {
    errorMessage.value = error.response?.data?.message || 'Logout failed. Please try again.';
    console.error('Logout error:', error);
    authStore.clearTokens();
    authStore.isAuthenticated = false;
    router.push('/dang-nhap');
  }
};

onMounted(fetchUserProfile); // Gọi fetchUserProfile khi component được mount
</script>

<style scoped>
.logout-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.logout-title {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 15px;
}

.logout-button {
  padding: 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: #c82333;
}
</style>
