<template>
  <div id="attendance-dashboard">
    <h1>Customers Checkin</h1>
    <div class="summary">
      <p><strong>Total Check-ins:</strong> <span class="highlight">{{ totalCheckIns }}</span></p>
      <p><strong>Late Check-ins:</strong> <span class="highlight">{{ lateCheckIns }}</span></p>
    </div>
    <p>Most Recent Attendance:</p>
    <div class="attendance-info" v-if="attendance">
      <p><strong>Timestamp:</strong> <span class="formatted-timestamp">{{ formatTimestamp(attendance.timestamp) }}</span></p>
      <p><strong>Customer:</strong> {{ attendance.employee_id }}</p>
      <img :src="attendance.photo_url" alt="Attendance Photo" class="attendance-photo" />
    </div>
    <div v-else>
      <p>No attendance data available</p>
    </div>
  </div>
</template>

<script>
import io from 'socket.io-client';

export default {
  name: 'AttEmp',
  data() {
    return {
      attendance: null,
      totalCheckIns: 0,
      lateCheckIns: 0,
      socket: null,
      socketAPI: process.env.VUE_APP_SOCKET_URL + '/api/attendance' // Moved socketAPI outside methods
    };
  },
  created() {
    // Initialize WebSocket connection
    const socketURL = process.env.VUE_APP_SOCKET_URL;
    this.socket = io(socketURL); // Store socket in this.socket

    // Listen for attendance updates
    this.socket.on('attendanceUpdate', (data) => {
      this.attendance = data;
      this.updateAttendanceSummary(data); // Update summary with new data
    });

    // Fetch initial attendance summary from API
    this.fetchAttendanceSummary();
  },
  beforeUnmount() {
    // Disconnect socket when component is unmounted
    if (this.socket) {
      this.socket.disconnect();
    }
  },
  methods: {
    fetchAttendanceSummary() {
      fetch(this.socketAPI, {  // Use this.socketAPI instead of local variable
        headers: {
          'x-api-key': process.env.VUE_APP_API_KEY // Use environment variable
        }
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch attendance summary');
        }
        return response.json();
      })
      .then((data) => {
        this.totalCheckIns = data.totalCheckIns;
        this.lateCheckIns = data.lateCheckIns;
      })
      .catch((error) => console.error('Error fetching attendance summary:', error));
    },

    updateAttendanceSummary(newAttendance) {
      this.totalCheckIns++; // Increase total check-ins count
      const checkInTime = new Date(newAttendance.timestamp);
      const lateTime = new Date(checkInTime);
      lateTime.setHours(7, 15, 0); // 7:15 AM GMT+7

      // Check for late check-in
      if (checkInTime > lateTime) {
        this.lateCheckIns++; // Increase late check-ins count
      }
    },

    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false, timeZone: 'Asia/Ho_Chi_Minh' };
      return date.toLocaleString('en-GB', options).replace(',', ''); // Format as dd/MM/yyyy HH:mm:ss
    }
  },
};
</script>

<style scoped>
#attendance-dashboard {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.summary {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  transition: transform 0.3s;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

.summary:hover {
  transform: translateY(-2px);
}

.highlight {
  color: #4CAF50; /* Green color for total check-ins */
  font-weight: bold;
}

.attendance-info {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  transition: transform 0.3s;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

.attendance-info:hover {
  transform: translateY(-2px);
}

.attendance-photo {
  width: 100px;
  height: auto;
  border-radius: 50%;
  margin-top: 10px;
}
</style>
