import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/components/Home.vue';
import AboutPage from '@/components/About.vue';
import SystemList from '@/components/SystemList.vue'; // Import component
import GasPrice from '@/components/GasPrice.vue'; // Import component
import HelloWorld from '@/components/HelloWorld.vue'; // Import component
import TimeLine from '@/components/Timeline.vue'; // Import component
import UserLogin from '@/components/UserLogin.vue'; // Import component
import UserLogout from '@/components/UserLogout.vue'; // Import component
import AttEmp from '@/components/AttEmp.vue';
import AttUser from '@/components/AttUser.vue';
import AttPe from '@/components/AttPerformance.vue';
import LoanCalculator from '@/components/LoanCalculator.vue';
import SavingCalculator from '@/components/SavingCalculator.vue';
import FuelInvestmentCalculator from '@/components/FuelInvestmentCalculator.vue';
import { useAuthStore } from '@/stores/authStore';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  { path: '/loan', name: 'LoanCalculator',component: LoanCalculator},
  { path: '/saving', name: 'SavingCalculator',component: SavingCalculator},
  { path: '/payback', name: 'FuelInvestmentCalculator',component: FuelInvestmentCalculator},
  {
    path: '/about',
    name: 'AboutPage',
    component: AboutPage
  },
  {
    path: '/att-em',
    name: 'AttEmp',
    component: AttEmp
  },
   {
        path: '/att-user',
        name: 'AttUser',
        component: AttUser
   },
  {
    path: '/att-pe',
    name: 'AttPe',
    component: AttPe
  },
  {
    path: '/systems',
    name: 'SystemList',
    component: SystemList, // Sử dụng component đã import
    props: (route) => ({ systems: route.query.systems })
  },
  {
    path: '/gas-price',
    name: 'GasPrice',
    component: GasPrice
  },
  {
    path: '/timeline',
    name: 'TimeLine',
    component: TimeLine
  },
  {
      path: '/dang-nhap',
      name: 'UserLogin',
      component: UserLogin
  },
  {
      path: '/dang-nhap',
      name: 'UserLogin',
      component: UserLogin,
      beforeEnter: (to, from, next) => {
        const authStore = useAuthStore();
        if (authStore.isAuthenticated) {
          next('/'); // Redirect về trang chính nếu đã đăng nhập
        } else {
          next(); // Cho phép truy cập nếu chưa đăng nhập
        }
      },
   },
  {
      path: '/logout',
      name: 'Logout',
      component: UserLogout,
      beforeEnter: (to, from, next) => {
        const authStore = useAuthStore();
        if (!authStore.isAuthenticated) {
          next('/dang-nhap'); // Redirect về trang login nếu chưa đăng nhập
        } else {
          next(); // Cho phép truy cập nếu đã đăng nhập
        }
      },
  },
  {
    path: '/hello-world',
    name: 'HelloWorld',
    component: HelloWorld,
    props: (route) => ({ msg: route.query.msg || 'Welcome to Your Vue.js App' }) // Truyền msg nếu có
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
