import { defineStore } from 'pinia';

export const useCalculatorStore = defineStore('calculator', {
  state: () => ({
    loanResult: null,
    savingResult: null,
    fuelInvestmentResult: null,
  }),
  actions: {
    // Hàm tính toán vay
        calculateLoan(principal, rate, time, type) {
              principal = parseFloat(principal.replace(/\./g, ""));
              if (isNaN(principal) || principal <= 0) {
                alert("Vốn vay phải là một số hợp lệ và lớn hơn 0.");
                return;
              }

              let result;
              if (type === 'fixed') {
                result = this.fixedLoanCalculator(principal, rate, time);
              } else if (type === 'declining') {
                result = this.decliningLoanCalculator(principal, rate, time);
              }
              this.loanResult = result;
            },
            fixedLoanCalculator(principal, rate, time) {
              const totalPayment = principal * (1 + rate / 100); // Tổng tiền phải trả
              const monthlyPayment = totalPayment / time; // Số tiền trả hàng tháng

              return {
                monthlyPayment,
                totalPayment,
              };
            },
            decliningLoanCalculator(principal, rate, time) {
              // Logic for declining balance calculation
              const monthlyRate = rate / 12 / 100;
              let remainingDebt = principal;
              const result = [];

              for (let month = 1; month <= time; month++) {
                const interest = remainingDebt * monthlyRate;
                const principalPayment = principal / time;
                const monthlyPayment = interest + principalPayment;

                result.push({
                  month,
                  monthlyPayment,
                  interest,
                  principalPayment,
                  remainingDebt,
                });

                remainingDebt -= principalPayment;
              }
              return result;
            },
    savingCalculator(principal, rate, time) {
          this.savingResult = []; // Reset kết quả cũ
          const monthlyRate = rate / 12 / 100; // Lãi suất tháng
          let accumulatedInterest = 0;

          for (let month = 1; month <= time; month++) {
            const interest = principal * monthlyRate; // Lãi tháng hiện tại
            accumulatedInterest += interest;

            this.savingResult.push({
              month,
              principal, // Tiền gốc không thay đổi
              interest: accumulatedInterest, // Tổng lãi tích lũy
            });
          }
        },

    // Hàm tính lãi suất tiết kiệm
    compoundSavingCalculator(principal, rate, time) {
      if (principal <= 0 || rate <= 0 || time <= 0) {
        throw new Error("Tất cả các giá trị phải lớn hơn 0.");
      }
      const result = [];
      let currentPrincipal = principal; // Không thay đổi giá trị gốc
      for (let month = 1; month <= time; month++) {
        const interest = currentPrincipal * (rate / 12 / 100);
        currentPrincipal += interest;
        result.push({
          month,
          principal: currentPrincipal,
          interest,
        });
      }
      this.savingResult = result;
    },
    // Hàm tính thời gian hoàn vốn trạm xăng
    fuelInvestmentCalculator(fuelCost, revenue, investment) {
          if (revenue <= fuelCost) {
            throw new Error("Doanh thu nhỏ hơn chi phí, bạn không thể hoàn vốn được!");
          }
          if (investment <= 0) {
            throw new Error("Vốn đầu tư phải lớn hơn 0.");
          }
          const months = investment / (revenue - fuelCost);
          this.fuelInvestmentResult = { months: Math.ceil(months) }; // Cập nhật state
    },
  }
});
